<template>
  <div class="d-flex align-items-center mb-4">
    <memlist-checkbox 
      :text="local_profilerow.name"
      v-model="local_profilerow.checked"
      @click="changed"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileSettingsRow',
  props: ['profilerow'],
  emits: ['change'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_profilerow: {}
    };
  },
  mounted() {
    this.local_profilerow = { ...this.profilerow };
  },
  watch: {
    
  },
  methods: {
    changed(value) {
      this.$emit('change', this.local_profilerow);
    }
  }
};
</script>
