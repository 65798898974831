<template>
  <div id="page-periods">
    <h5 class="m-4">{{ $t('PAGES.SETTINGS.SETTINGS') }}</h5>

    <b-card title="" class="mb-2" hide-footer>
      
      <v-tabs 
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        background-color="white" 
        color="accent-4" 
        left>
        <v-tab>{{ $t('PAGES.SETTINGS.PUBLIC') }}</v-tab>
        <v-tab>{{ $t('PAGES.SETTINGS.LINKS') }}</v-tab>
        <v-tab>{{ $t('PAGES.SETTINGS.REGISTRATION') }}</v-tab>
        <!-- <v-tab>Titlar</v-tab> -->
        <v-tab>{{ $t('PAGES.SETTINGS.BANK') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.MEMBER_PROFILE') }}</v-tab>
        
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.PAYMENT') }}</v-tab>
        
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.FORTNOX') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.API_CONNECTION') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.EDUCATIONS') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.MEMBER_REQUIREMENTS') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.EXTRA_DATA') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id">{{ $t('PAGES.SETTINGS.LOGIN') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id && isTHS">DNS</v-tab>
        <v-tab v-if="company.sid === company.company_id && isTHS">{{ $t('PAGES.SETTINGS.EMAILS') }}</v-tab>
        <v-tab v-if="company.sid === company.company_id && isTHS">{{ $t('PAGES.SETTINGS.FAITH') }}</v-tab>
        <v-tab v-if="isTHS">{{ $t('PAGES.SETTINGS.HIDDEN') }}</v-tab>

        <v-tab-item>
          <BasicCompanySettings
            :company="company"
            :regions="regions"
            :extras="extras"
            @selectRegion1="selectRegion1"
          />
        </v-tab-item>

        <v-tab-item>
          <Links :settings="company.company_settings" />
        </v-tab-item>

        <v-tab-item>
          <Registration
            :regions="regions"
            :company="company"
            :registration="registration"
            @updateRegistration="updateRegistration"
            @selectRegion="selectRegion2"
          />
        </v-tab-item>

        <!-- <v-tab-item>
          <Titles :settings="company.settings" @updateSettings="updateTitleSettings" />
        </v-tab-item> -->

        <v-tab-item>
          <Bank :company="company"/>
        </v-tab-item>


        <v-tab-item>
          <MemberProfileSettings :company="company" v-if="company.sid === company.company_id"/>
        </v-tab-item>

        <v-tab-item>
          <Payment v-if="company.sid === company.company_id" :company="company" :settings="company.company_settings" />
        </v-tab-item>

        <v-tab-item>
          <Fortnox v-if="company.sid === company.company_id" :company="company" />
        </v-tab-item>

        <v-tab-item>
          <APIConnection
          v-if="company.sid === company.company_id"
          />
        </v-tab-item>

        <v-tab-item>
          <EducationSettings v-if="company.sid === company.company_id" :settings="company.company_settings" />
        </v-tab-item>

        <v-tab-item>
          <Requirements v-if="company.sid === company.company_id" :company="company" />
        </v-tab-item>

        <v-tab-item v-if="company.sid === company.company_id">
          <ExtraData />
        </v-tab-item>

        <v-tab-item v-if="company.sid === company.company_id">
          <LoginSettings />
        </v-tab-item>


        <v-tab-item v-if="company.sid === company.company_id && isTHS">
          <Domains :company="company" />
        </v-tab-item>

        
        <v-tab-item v-if="company.sid === company.company_id && isTHS">
          <EmailsTab/>
        </v-tab-item>

        <v-tab-item>
          <FaithSettings v-if="company.sid === company.company_id && isTHS" :company="company" :settings="company.company_settings" />
        </v-tab-item>


        <v-tab-item>
          <HiddenSettings v-if="isTHS" :settings="company.company_settings" />
        </v-tab-item>
      </v-tabs>
    </b-card>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import APIConnection from '@/view/pages/ml/settings/APIConnection.vue';
import Fortnox from '@/view/pages/ml/settings/Fortnox.vue';
import Payment from '@/view/pages/ml/settings/Payment.vue';
//import Pricing from '@/view/pages/ml/settings/Pricing.vue';
import Domains from '@/view/pages/ml/settings/Domains.vue';
import Requirements from '@/view/pages/ml/settings/Requirements.vue';
import EducationSettings from '@/view/pages/ml/settings/EducationSettings.vue';
import FaithSettings from '@/view/pages/ml/settings/FaithSettings.vue';
import Bank from '@/view/pages/ml/settings/Bank.vue';
import Titles from '@/view/pages/ml/settings/Titles.vue';
import Registration from '@/view/pages/ml/settings/Registration.vue';
import BasicCompanySettings from '@/view/pages/ml/settings/BasicCompanySettings.vue';
import ExtraData from '@/view/pages/ml/settings/ExtraData.vue';
import Links from '@/view/pages/ml/settings/Links.vue';
import MemberProfileSettings from '@/view/pages/ml/settings/MemberProfileSettings.vue';
import EmailsTab from '@/view/pages/ml/settings/EmailsTab.vue';
import LoginSettings from '@/view/pages/ml/settings/LoginSettings.vue';
import HiddenSettings from '@/view/pages/ml/settings/HiddenSettings.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'SettingsPage',
  components: {
    HiddenSettings,
    APIConnection,
    Fortnox,
    Payment, 
    //Pricing,
    Domains,
    Requirements,
    Bank,
    Titles,
    Registration,
    BasicCompanySettings,
    Links,
    MemberProfileSettings,
    ExtraData,
    EducationSettings,
    FaithSettings,
    EmailsTab,
    LoginSettings
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },
  mixins: [ toasts ],
  async mounted() {
    if (this.$route.query.fortnox === 'success') {
      this.toastr('success', this.$t('COMMON.OK'), 'Fortnox är nu kopplat till denna förening');
      this.$router.replace({
        ...this.$route.currentRoute,
        query: {
          fortnox: undefined
        }
      });
    }
    this.company_id = this.$route.params.id;
    this.loadData();
  },
  data() {
    return {
      registration: {},
      extras: [],
      regions: {},
      regionOptions: [],
      sourceRegions: [],
      cmmuneOptions: [],
      communeOptions1: [],
      communeOptions2: [],
      company_id: null,
      company: {
        origins: '',
        smtp: {
          use_custom: false,
          memlist_prefix: '',
          from: '',
          password: '',
          server: '',
          port: 0,
          security: ''
        },
        settings: {}
      }
    };
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    },
    currentPeriodId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    }
  },
  methods: {
    loadOnValidPeriodAndCompany() {
      if (this.currentCompanyId && this.currentPeriodId && this.currentPeriodId !== -1) {

        this.isTopCompany = this.sid === this.currentCompanyId;
        this.loadData();
      }
    },
    updateTitleSettings(titles) {
      this.company.settings.titles = titles;

      this.updateSettings(this.company.settings);
    },
    putCompany(data) {
      axios
        .put('/company', data)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
            this.loadData();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    },
    updateRegistration(registration) {
      this.putCompany({ company_id: this.currentCompanyId, registration: registration });
    },
    updateSettings(settings) {
      this.putCompany({ company_id: this.currentCompanyId, settings: settings });
    },
    loadRegions() {
      axios
        .get('https://zip.memlist.se/api/v1/regions_communes')
        .then(res => {
          this.regions = res.data.regions;
          this.regionOptions = [];

          for (var k in this.regions) {
            this.regionOptions.push({ value: k, text: this.regions[k].region });
          }

          this.regionOptions.sort(function(a, b) {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta regioner');
        });
    },
    selectRegion1(region_code) {
      this.loadCommunes(region_code, 1);
    },
    selectRegion2(region_codes) {
      this.communeOptions2 = [];

      for (var i = 0; i < region_codes.length; ++i) {
        var comm = this.getCommunes(region_codes[i].code);

        for (var c = 0; c < comm.length; ++c) {
          this.communeOptions2.push({ code: comm[c].value, text: comm[c].text });
        }
      }
    },
    getCommunes(region_code) {
      var comm = [];

      var communes = this.regions[region_code].communes;

      for (var k in communes) {
        comm.push({ value: k, text: communes[k] });
      }

      comm.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      return comm;
    },
    loadCommunes(selectedRegion, option) {

      this.communeOptions = [];

      var communes = this.regions[selectedRegion].communes;

      for (var k in communes) {
        this.communeOptions.push({ value: k, text: communes[k] });
      }

      this.communeOptions.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      if (option == 1) {
        this.communeOptions1 = this.communeOptions;
      } else {
        this.communeOptions2 = this.communeOptions;
      }
    },
    loadExtraData() {
      axios
        .get(`/extra_data?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.extras = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta extradata');
        });
    },
    loadData() {
      this.loadRegions();
      let id = this.currentCompanyId;
      if (this.company_id !== '' && this.company_id !== null && this.company_id !== undefined) {
        id = this.company_id;
      }
      axios
        .get(`/company/admin?company_id=${id}`)
        .then(res => {
          if (res.status === 200) {
            this.company = res.data;
            this.registration = { ...this.company.registration };
            this.loadExtraData();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta föreningsinställningar');
        });
    },
    
  }
};
</script>
