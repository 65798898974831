
<template>
  <div class="px-5 mt-8" id="page-faith">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <b-form class="mt-8" @submit.prevent="saveData">

            <b-row>
              <b-col>
                <memlist-checkbox 
                  :text="$t('PAGES.SETTINGS.IS_NORMAL')"
                  v-model="form.is_normal"
                />
                
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <memlist-checkbox 
                  :text="$t('PAGES.SETTINGS.IS_MUCF')"
                  v-model="form.is_mucf"
                />
                
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <memlist-checkbox 
                  :text="$t('PAGES.SETTINGS.IS_FAITH')"
                  v-model="form.is_faith"
                />
              </b-col>
            </b-row>
            
            <b-row>
              <b-col>
                <memlist-checkbox 
                  :text="$t('PAGES.SETTINGS.IS_STUDENT')"
                  v-model="form.is_student"
                />
                
              </b-col>
            </b-row>
            
            
            <b-row>
              <b-col>
                <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
              </b-col>
            </b-row>
            

            
          </b-form>
        </b-card></b-col
      >
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  SET_SETTINGS
} from '@/core/services/store/common.module';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import store from '@/core/services/store';

export default {
  name: 'faith-settings',
  props: ['settings'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      form: {
        is_faith: false,
        is_mucf: false,
        is_student: false,
        is_normal: false,
      }
    };
  },
  mounted() {
  
  },
  watch: {
    settings: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.form.is_faith = this.settings.is_faith;
        this.form.is_student = this.settings.is_student;
        this.form.is_mucf = this.settings.is_mucf;
        this.form.is_normal = this.settings.is_normal;
      },
      immediate: true
    },
  },

  methods: {
    
    saveData() {
    
      axios.put(`/company/company_settings/${this.currentCompanyId}`, this.form)
      .then(res => {
        if (res.status === 200) {
          store.dispatch(SET_SETTINGS, res.data);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      })
      .catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      });
    }
  }
};
</script>
            